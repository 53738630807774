import React from "react";
import ReactDOM from "react-dom";
import "./style.css";

class Results extends React.Component {
  render() {
    // Don't return dom if no players inputted yet
    if (this.props.pots.length < 1) return null;

    const show_pots = this.props.pots.map((pot, i) => {
      const is_last_pot = pot.players.length > 1 ? false : true;
      return (
        <div key={"pot-" + i} className="pot">
          <div className="columns">
            <div className="pot-num column-25">
              {is_last_pot ? "Return" : "Pot #" + (i + 1)}
            </div>
            <div className="column-75 pot-result">{pot.total}</div>
          </div>
          <div className="pot-players">Players: {pot.players.join(", ")}</div>
        </div>
      );
    });

    return (
      <section id="results">
        <div className="pots">{show_pots}</div>
        <div className="is-clearfix">
          <button
            className="button btn-clear-calc"
            onClick={this.props.onClickResetCalc}
          >
            Reset Calculator
          </button>
        </div>
      </section>
    );
  }
}

// ========================================

class Turns extends React.Component {
  constructor(props) {
    super(props);
    // onClicks & onChange main return function
    this.onChangeInputName = this.onChangeInputName.bind(this);
    this.onChangeInputBet = this.onChangeInputBet.bind(this);
    this.onClickAddPlayer = this.onClickAddPlayer.bind(this);
    this.onKeyPressBetInput = this.onKeyPressBetInput.bind(this);
  }

  onChangeEditBet(player_index, e) {
    this.props.onChangeEditBet(player_index, e.target.value);
  }

  onClickRemovePlayer(player_index, e) {
    this.props.onClickRemovePlayer(player_index);
  }

  onChangeInputName(e) {
    this.props.onChangeInputName(e.target.value);
  }

  onChangeInputBet(e) {
    this.props.onChangeInputBet(e.target.value);
  }

  onClickAddPlayer(e) {
    e.preventDefault();
    this.props.onClickAddPlayer();
  }

  onKeyPressBetInput(e) {
    if (e.key === "Enter") this.props.onClickAddPlayer();
  }

  render() {
    const show_players = this.props.addedPlayers.map((player, i) => {
      let onClickRemovePlayer = this.onClickRemovePlayer.bind(this, i);
      let onChangeEditBet = this.onChangeEditBet.bind(this, i);
      return (
        <div key={"player-" + i} className="player-added-wrapper columns">
          <div className="column-50 added-player-name">
            <span>{player.name}</span>
          </div>
          <div className="column-40">
            <input
              className="input"
              type="number"
              name="player_bet"
              value={player.bet}
              onChange={onChangeEditBet}
            />
          </div>
          <div className="column-10">
            <button onClick={onClickRemovePlayer} className="button is-danger">
              -
            </button>
          </div>
        </div>
      );
    });

    return (
      <section id="bets">
        <header className="columns bets-header">
          <div className="column-50">Players</div>
          <div className="column-50">Bet Size</div>
        </header>
        <div className="players">
          <div className="player-add-wrapper columns">
            <div className="column-50">
              <input
                className="input"
                type="text"
                name="player_name"
                placeholder="Player Name"
                value={this.props.formAddPlayerName}
                onChange={this.onChangeInputName}
                autoFocus
              />
            </div>
            <div className="column-40">
              <input
                className="input"
                type="number"
                name="player_bet"
                placeholder="Player Bet"
                value={this.props.formAddPlayerBet}
                onChange={this.onChangeInputBet}
                min="0"
                onKeyPress={this.onKeyPressBetInput}
              />
            </div>
            <div className="column-10">
              <button
                className="button is-dark"
                onClick={this.onClickAddPlayer}
              >
                +
              </button>
            </div>
          </div>

          {show_players}
        </div>
      </section>
    );
  }
}

class PokerBetCalculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formAddPlayerName: "",
      formAddPlayerBet: "",
      players: [],
    };

    this.onChangeInputName = this.onChangeInputName.bind(this);
    this.onChangeInputBet = this.onChangeInputBet.bind(this);
    this.onClickAddPlayer = this.onClickAddPlayer.bind(this);
    // onClicks & onChange for show_players
    this.onChangeEditBet = this.onChangeEditBet.bind(this);
    this.onClickRemovePlayer = this.onClickRemovePlayer.bind(this);
    // Results: clear calculator
    this.onClickResetCalc = this.onClickResetCalc.bind(this);
  }

  /* Results: clear calculator */

  onClickResetCalc() {
    this.setState({
      players: [],
    });
  }

  /* onClicks & onChange for show_players */

  onChangeEditBet(player_index, bet) {
    const players = this.state.players.slice();
    players[player_index].bet = parseInt(bet, 10);
    this.setState({
      players: players,
    });
  }

  onClickRemovePlayer(player_index) {
    const players = this.state.players.slice();
    players.splice(player_index, 1);
    this.setState({
      players: players,
    });
  }

  onChangeInputName(name) {
    this.setState({
      formAddPlayerName: name,
    });
  }

  onChangeInputBet(bet) {
    if (bet.length > 0) {
      bet = parseInt(bet, 10);
      if (isNaN(bet)) return;
    }
    this.setState({
      formAddPlayerBet: bet,
    });
  }

  onClickAddPlayer() {
    if (
      this.state.formAddPlayerName === "" ||
      this.state.formAddPlayerBet === ""
    )
      return;
    const players = this.state.players.slice();
    // push new player & bet to players
    const formAddPlayer = {
      name: this.state.formAddPlayerName,
      bet: this.state.formAddPlayerBet,
    };
    players.push(formAddPlayer);
    this.setState({
      players: players,
      formAddPlayerName: "",
      formAddPlayerBet: "",
    });
  }

  render() {
    const players = this.state.players;
    const pots = calculateThePot(players);

    return (
      <div>
        <Turns
          onClickAddPlayer={this.onClickAddPlayer}
          onChangeInputBet={this.onChangeInputBet}
          onChangeInputName={this.onChangeInputName}
          formAddPlayerName={this.state.formAddPlayerName}
          formAddPlayerBet={this.state.formAddPlayerBet}
          addedPlayers={this.state.players}
          onChangeEditBet={this.onChangeEditBet}
          onClickRemovePlayer={this.onClickRemovePlayer}
        />
        <Results pots={pots} onClickResetCalc={this.onClickResetCalc} />
      </div>
    );
  }
}

// ========================================

ReactDOM.render(<PokerBetCalculator />, document.getElementById("root"));

// ========================================

function calculateThePot(players) {
  let pot_result = [];
  let next_players = players;
  let current_players = [];
  let min = 0;
  let current_pot = {};

  // Continue split the pot while there are players
  while (next_players.length > 0) {
    // Reset vars
    current_players = next_players;
    min = findPlayersMinBet(current_players);
    current_pot = {
      total: 0,
      players: [],
    };
    next_players = [];

    // Calculate
    for (let i = 0; i < current_players.length; i++) {
      const name = current_players[i].name;
      const standing_bet = current_players[i].bet - min;

      current_pot.total += min;
      current_pot.players.push(name);

      if (standing_bet > 0) {
        next_players.push({
          name: name,
          bet: standing_bet,
        });
      }
    }
    pot_result.push(current_pot);
  }
  return pot_result;
}

// Find min bet in players var
function findPlayersMinBet(players) {
  var min = Infinity;
  for (let i = 0; i < players.length; i++) {
    if (players[i]["bet"] < min) {
      min = players[i]["bet"];
    }
  }
  return min;
}
